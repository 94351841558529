.annoucement-carousel {
  position: relative;
  border-radius: 0px 5px;
  .slick-slider {
    .slick-prev {
      top: 5px;
      left: auto;
      right: 68px;
      z-index: 1;
      &::before {
        content: '';
      }
      // &::after {
      //   color: black;
      // }
    }
    .slick-next {
      top: 5px;
      right: 5px;
      z-index: 1;
      &::before {
        content: '';
      }
      // &::after {
      //   color: black;
      // }
    }
  }
  .counter {
    position: absolute;
    right: 32px;
    top: 25%;
  }
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0c46cde;
  color: #fff;
}
.grey {
  color: #7a7979 !important;
  width: 90% !important;
}
.blink-text {
  text-decoration: underline !important;
}
