.form__button-vertical-align {
  margin-top: 0px;
}

.pull-right {
  float: right;
}

.form .btn-sm {
  height: 32px;
}

.back-btn {
  position: absolute;
  left: 15px;
  top: -15px;
}

.left-title {
  display: inline;
}
.disabled-btn {
  cursor: not-allowed !important;
}

.center-align-btn-toolbar {
  margin: 20px auto;
}

.right-align-btn-toolbar {
  margin: 10px 0px 10px auto;
}

.container__wrap {
  padding-top: 60px;
  padding-bottom: 60px;
}

.no-menu-container {
  margin-top: 40px;
}

.icon-menu-row {
  margin: 5px 0px;
}

.react-transform-component {
  margin: 10px auto !important;
}

.icon-menu-row-title {
  margin-top: 5px;
}

.preview-modal {
  min-width: 450px;
  .modal-content {
    padding-top: 0px;
  }
  .modal-header {
    padding: 0px;
    border-bottom: none;
    margin-top: 20px;
  }
}

.dual-list-modal {
  min-width: 450px;
  .modal-content {
    padding: 30px;
  }
  .modal-header {
    padding: 0px;
    border-bottom: none;
    margin-top: 20px;
  }
  .react-dual-listbox {
    margin: 0px auto;
    min-width: 370px;
  }
  .rdl-control-label {
    text-align: center;
  }
}

.breadcrumb {
  background-color: transparent !important;
}

.page-title {
  font-size: 1.3rem;
  font-weight: 300;
  display: inline;
}

.page-title:last-child {
  margin-bottom: 0px;
}

.page-title-dropdown {
  padding: 0px !important;
  font-size: 1.3rem !important;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
  font-weight: 300 !important;
  color: #232329;

  &:before {
    background-color: transparent;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    text-decoration: none;
    border-color: transparent;
    background-color: transparent !important;
  }
}
.sample-file-dropdown {
  @extend .page-title-dropdown;
  font-size: 0.8rem !important;
  color: #70bbfd;
  font-weight: 600 !important;
  &:hover {
    color: #70bbfd;
  }
}

.page-title-dropdown:hover {
  text-decoration: none;
}

.dropdown-header {
  padding: 0.5rem 0.8rem;
  font-weight: bold;
}

.dropdown-item {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  padding: 0.5rem 1.5rem;
}
.dropdwn-menu-item {
  font-size: 0.8rem;
}
.helper-text {
  padding-top: 5px;
  margin-right: 4px !important;
}

.icon-btn {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  padding: 4px 9px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 3px;
  font-size: 0.9rem;
  line-height: 1.42857;
  .icon {
    position: relative;
    top: 6px;
    height: 13px;
    width: 13px;
    margin: 2px 5px 0 0;
  }
  &.without-icon {
    line-height: 1.5;
  }
}

.form-field-btn {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  padding: 8px 25px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 3px;
  font-size: 0.9rem;
  line-height: 1.3;
}

.dropdown-toggle::after {
  position: relative;
  top: 3px;
}

.required {
  color: #e54643;
}

.required:after {
  content: '*';
  color: #e54643;
}

.form__form-group-label {
  font-size: 0.9rem;
  font-weight: 300;
}

.form__form-group-field {
  font-size: 0.8rem;
  font-weight: 300;
}

.form__button-toolbar {
  margin-left: 15px !important;
  margin-bottom: 20px !important;
  button {
    margin-right: 10px !important;
    margin: 0px;
    padding: 2px 9px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  }
}

.green-bubble {
  width: 6px;
  height: 6px;
  background-color: #28ba9b;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
  margin-bottom: 1px;
}

.close-details {
  margin-left: 10px;
  float: right;
  color: #777;
  height: 13px;
  width: 13px;
}

.mr15 {
  margin-right: 15px;
}

.theme-light .react-select__control,
.theme-light .form input,
.theme-light .form textarea {
  border: 1px solid #ced4da;
}

.form input:focus,
.form input:active,
.form input:hover,
.form textarea:focus,
.form textarea:active,
.form textarea:hover {
  outline: none;
  border-color: #4ce1b6;
}

.line-item-table {
  width: 95%;
  border: 1px solid #ccc;
}

// .line-item-header {
//   border-top: 1px solid #ccc;
//   border-bottom: 1px solid #ccc;
// }

.border-top-bottom {
  border: none !important;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}

.items-table-wrapper {
  overflow-x: auto;
  max-height: 650px;
}

.line-item-table .line-item-header .line-item-column {
  padding: 8px 10px;
  color: #777;
  background-color: #f9f9f9;
}

.line-item-table .line-item-column {
  padding: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  vertical-align: top;
  word-wrap: break-word;
}
.font-bold {
  font-weight: bold;
}

.custom-table {
  .line-item-table {
    .line-item-column {
      padding: 0;
      border: 1px solid #ccc;
      background-color: #fff;
      vertical-align: middle;
      word-wrap: break-word;
    }

    .line-item-header {
      .line-item-column {
        padding: 8px 10px;
        color: #777;
        background-color: #f9f9f9;
      }
    }
  }
}

.line-item-table-data {
  padding: 10px;
}
.line-item-table-data-action {
  padding: 10px;
  text-align: center;
}

.line-item-table .line-item-body {
  margin-bottom: 13px;
}

.line-item-table .line-item-body .new-line-item {
  opacity: 1;
  margin-left: -20px;
  padding-left: 20px;
}
.line-item-table .line-item-body .line-item {
  // opacity: 0;
  transition: opacity 0.3s linear;
}

.line-item-table .invoice-discount .item-details {
  width: 37%;
}

.line-item-table .line-item-body .line-item .action {
  font-size: 15px;
  cursor: pointer;
}
.action-credit {
  font-size: 15px;
  cursor: pointer;
  color: red;
}

.item-details {
  min-width: 370px;
}

.item-actions-container {
  position: relative;
}

.item-details {
  min-width: 370px;
}
.over-flow {
  max-width: 100%;
}
.list-header .list-title > a,
.list-notes,
.list-primary .name,
.over-flow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-actions-container .icon-drag {
  position: absolute;
  color: #ddd;
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  left: -17px;
}

svg.icon {
  /* width: 16px; */
  height: 16px;
  fill: currentColor;
}
.item-details .item-field {
  width: 100%;
}

.service-type-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  display: block;
  margin-top: 15px;
  margin-left: 12px;
}

.item-description {
  border: none;
  box-shadow: none;
  resize: none;
  margin: 15px 13px 13px 13px;
  width: 91% !important;
  border: 1px solid #ced4da !important;
}
.item-description:focus,
.item-description:hover {
  border: 1px solid #4ce1b6 !important;
}
.line-item-table .line-item-column .form-control:focus {
  border: 1px solid #4ce1b6;
}
.line-item-table .line-item-column .form-control {
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}
.line-item-body .item-amount,
.line-item-body .item-amount-us {
  background-color: #fbfbfb;
  padding: 10px;
  color: #777;
  font-size: 14px;
}

// .line-item-column {
//   .form__form-group-error {
//     margin-left: 15px;
//   }
// }

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.42857;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.item-actions-container .cardDrag-actions {
  position: absolute;
  right: 1px;
  top: 45px;
}

.item-actions-container .item-actions {
  position: absolute;
  right: -35px;
  top: -20px;
}
.line-item-table .line-item-body .line-item .icon-cancel-circled {
  color: #fab2b1;
}
svg.icon.icon-sm {
  height: 14px;
  width: 14px;
}

.total-section {
  margin-right: 2px !important;
  margin-bottom: 20px;
}

.total-section .total-row {
  min-height: 28px;
}
.mt-3,
.my-3 {
  margin-top: 10px !important;
}

.total-section .total-row .total-label {
  display: inline-block;
  min-height: 28px;
  width: 40%;
  padding: 10px 1%;
}
.total-section .total-row .total-amount {
  float: right;
  padding: 10px 1.5%;
  min-height: 28px;
  width: 60%;
  margin-left: -1%;
  text-align: right;
  color: #777666;
}
.total-section .total-row {
  min-height: 28px;
}
.total-section .total-row {
  min-height: 28px;
}

.mb-3,
.my-3 {
  margin-bottom: 10px !important;
}
.total-section .total-row.gross-total .total-label {
  border-right: none;
}

.total-section .total-row.gross-total .total-amount,
.total-section .total-row.gross-total .total-label {
  font-weight: 600;
  font-size: 16px;
}
.total-section .total-row .total-label {
  display: inline-block;
  min-height: 28px;
  width: 40%;
  padding: 10px 1%;
}
.total-section .total-row.gross-total .total-amount {
  border-left: none;
  color: #333;
}

.total-section .total-row.gross-total .total-amount,
.total-section .total-row.gross-total .total-label {
  font-weight: 600;
  font-size: 16px;
}
.total-section .total-row .total-amount {
  float: right;
  padding: 10px 1.5%;
  min-height: 28px;
  width: 60%;
  margin-left: -1%;
  text-align: right;
  color: #777666;
}
.rounded-xlg {
  border-radius: 0.5rem;
}

.w-90 {
  width: 90%;
}

.associated-txn:hover,
.grey-bg {
  background-color: #fbfafa !important;
}

.white-bg {
  background-color: #ffffff;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mr-4,
.mx-4 {
  margin-right: 15px !important;
}
.mt-5,
.my-5 {
  margin-top: 20px !important;
}

.mt-6,
.my-6 {
  margin-top: 30px !important;
}

.pt-4 {
  padding-top: 15px !important;
}

.pb-4 {
  padding-bottom: 15px !important;
}

.add-item-btn {
  margin-top: 10px;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.light-border-control {
  border: 1px solid #f2f4f7 !important;
}

// .form-control.item-description {
//   margin-top: 30px;
// }

.icon-btn.delete-btn svg {
  top: 0px;
}

.edit-photo-btn {
  position: absolute;
  bottom: 100%;
  height: 100%;
  width: 100%;
}

.leave-action-cell {
  width: 170px;
  button {
    padding-left: 0px;
  }
}

.pdf-content {
  width: 8.27in;
  height: 11.69in;
  margin: 10px auto;
  border: 1px solid #ccc;
}

ul.sidebar__submenu span.sidebar__category-icon {
  top: 10px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 90%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 500;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  // overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.inputfile-4 + label {
  color: #4094dd;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
  color: #92428e;
}

.inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #70bbfd;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
  background-color: #92428e;
}

.inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

.box {
  background-color: #e9e9f0;
  padding: 1.5rem 0.5rem;
}

.box + .box {
  margin-top: 2.5rem;
}

.clr-blue {
  color: $color-blue !important;

  &:hover {
    color: $color-blue-hover;
  }
}

.clr-primary {
  color: $color-primary !important;

  &:hover {
    color: $color-primary-hover;
  }
}

.clr-add {
  color: $color-additional !important;

  &:hover {
    color: $color-additional-hover;
  }
}

.btn-link--custom {
  color: $color-blue;
  cursor: pointer;
  text-align: right;
  outline: none;
  border: none;
  background: none;
  padding: 0;
}

.text-nowrap {
  white-space: nowrap;
}

.no-border {
  border: none !important;
}

.no-left-border {
  border-left: none !important;
}

.no-right-border {
  border-right: none !important;
}

.no-top-border {
  border-top: none !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.no-left-right-border {
  border-left: none !important;
  border-right: none !important;
}

.no-top-bottom-border {
  border-top: none !important;
  border-bottom: none !important;
}

.table-bottom-border {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
.table-mb {
  margin-top: 50px;
}

.table-top-border {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.table-right-border {
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.table-horizontal-border {
  border-top: none !important;
  border-bottom: none !important;
}

.table-vertical-border {
  border-left: none !important;
  border-right: none !important;
}

.note {
  color: $color-additional;
  padding: 24px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: lighter;
}

.payment-summary-wrapper {
  width: 95%;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.payment-summary {
  min-width: 300px;
  max-width: 100%;
  padding: 10px;
  font-size: 14px;
}

.credit-summary {
  height: 50px;
  width: 200px;
  margin-left: auto;
  margin-top: 10px;
  font-size: 14px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.2;
}

.line-item-row {
  height: 20px;
}

.custom-textarea {
  min-height: 60px !important;
}

.icon-btn svg {
  height: auto;
  width: auto;
  margin: 0;
}

// Templates Styles Start
// **********************

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
  font-family: 'WebFont-Open Sans';
  src: local(Open Sans),
    url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
}

.receipt {
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0px 2px 5px #c5c1c1, 0px 0px 5px #c5c1c1;
}

/* Styles for RTL compat */

/* Helper Classes */

.inline {
  display: inline-block;
}
.v-top {
  vertical-align: top;
}
.text-align-right {
  text-align: right;
}
.rtl .text-align-right {
  text-align: left;
}
.text-align-left {
  text-align: left;
}
.rtl .text-align-left {
  text-align: right;
}

/* Helper Classes End */

.item-details-inline {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  max-width: 70%;
}

.total-in-words-container {
  width: 100%;
  margin-top: 10px;
}
.total-in-words-label {
  vertical-align: top;
  padding: 0 10px;
}
.total-in-words-value {
  width: 170px;
}
.total-section-label {
  padding: 5px 10px 5px 0;
  vertical-align: middle;
}
.total-section-value {
  width: 120px;
  vertical-align: middle;
  padding: 10px 10px 10px 5px;
}
.rtl .total-section-value {
  padding: 10px 5px 10px 10px;
}

.tax-summary-description {
  color: #727272;
  font-size: 8pt;
}

.bharatqr-bg {
  background-color: #f4f3f8;
}

/* Overrides/Patches for RTL compat */
.rtl th {
  text-align: inherit; /* Specifically setting th as inherit for supporting RTL */
}
/* Overrides/Patches End */

/* Subject field styles */
.subject-block {
  margin-top: 20px;
}
.subject-block-value {
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 14pt;
  margin-top: 5px;
}
/* Subject field styles End*/

// Invoice Template Style

.pcs-template {
  font-family: Open Sans, 'WebFont-Open Sans';
  font-size: 10pt;
  color: #333333;
  background: #ffffff;
}

.pcs-header-content {
  font-size: 9pt;
  color: #333333;
  background-color: #ffffff;
}
.pcs-template-body {
  padding: 0 0.4in 0 0.55in;
}
.pcs-template-footer {
  height: 0.7in;
  font-size: 6pt;
  color: #aaaaaa;
  padding: 0 0.4in 0 0.55in;
  background-color: #ffffff;
}
.pcs-footer-content {
  word-wrap: break-word;
  color: #aaaaaa;
  border-top: 1px solid #adadad;
}

.pcs-label {
  color: #333333;
}
.pcs-entity-title {
  font-size: 28pt;
  color: #000000;
}
.pcs-orgname {
  font-size: 10pt;
  color: #333333;
}
.pcs-customer-name {
  font-size: 9pt;
  color: #333333;
}
.pcs-itemtable-header {
  font-size: 9pt;
  color: #ffffff;
  background-color: #3c3d3a;
}
.pcs-itemtable-breakword {
  word-wrap: break-word;
}
.pcs-taxtable-header {
  font-size: 9pt;
  color: black;
  background-color: #f7e18b;
  font-weight: bold;
}
.breakrow-inside {
  page-break-inside: avoid;
}
.breakrow-after {
  page-break-after: auto;
}
.pcs-item-row {
  font-size: 9pt;
  border-bottom: 1px solid #adadad;
  background-color: #ffffff;
  color: #000000;
}
.pcs-item-sku {
  margin-top: 2px;
  font-size: 10px;
  color: #444444;
}
.pcs-item-desc {
  color: #727272;
  font-size: 9pt;
}
.pcs-balance {
  background-color: #f5f4f3;
  font-size: 9pt;
  color: #000000;
}
.pcs-totals {
  font-size: 9pt;
  color: #000000;
  background-color: #ffffff;
}
.pcs-notes {
  font-size: 8pt;
}
.pcs-terms {
  font-size: 8pt;
}
.pcs-header-first {
  background-color: #ffffff;
  font-size: 9pt;
  color: #333333;
  height: auto;
}

.pcs-status {
  font-size: 15pt;
  border: 3px solid;
  padding: 3px 8px;
}
.billto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}
.shipto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}

@page :first {
  @top-center {
    content: element(header);
  }
  margin-top: 0.7in;
}

.pcs-template-header {
  padding: 0 0.4in 0 0.55in;
  height: 0.7in;
}

.pcs-template-fill-emptydiv {
  display: table-cell;
  content: ' ';
  width: 100%;
}

.lineitem-header {
  padding: 5px 10px 5px 5px;
  word-wrap: break-word;
}
.rtl .lineitem-header {
  padding: 5px 5px 5px 10px;
}
.lineitem-column {
  padding: 10px 10px 5px 10px;
  word-wrap: break-word;
  vertical-align: top;
}
.lineitem-content-right {
  padding: 10px 10px 10px 5px;
}
.rtl .lineitem-content-right {
  padding: 10px 5px 10px 10px;
}
.total-number-section {
  width: 45%;
  padding: 10px 10px 3px 3px;
  font-size: 9pt;
  float: left;
}
.rtl .total-number-section {
  float: right;
}
.pcs-total-section {
  width: 50%;
  float: right;
}
.rtl .pcs-total-section {
  float: left;
}

// Invoice Template Style End

// Styles for Payment Template Start

.dcs-template {
  font-family: Open Sans, 'WebFont-Open Sans';
  font-size: 11pt;
  color: #333333;
  background: #ffffff;
}

.dcs-header-content {
  font-size: 8pt;
  color: #333333;
  background-color: #ffffff;
}
.dcs-template-body {
  padding: 0 0.4in 0 0.55in;
}
.dcs-template-footer {
  height: 0.7in;
  font-size: 6pt;
  color: #aaaaaa;
  padding: 0 0.4in 0 0.55in;
  background-color: #ffffff;
}
.dcs-footer-content {
  word-wrap: break-word;
  color: #aaaaaa;
  border-top: 1px solid #ece7e7;
}

.dcs-label {
  color: #777777 !important;
}
.dcs-entity-title {
  font-size: 13pt;
  color: #333333;
}
.dcs-orgname {
  font-size: 15pt;
  color: #333333;
}
.dcs-customer-name {
  font-size: 12pt;
  color: #333333;
}
.dcs-itemtable-header {
  font-size: 11pt;
  color: #333333;
  background-color: #eff0f1;
}
.dcs-itemtable-breakword {
  word-wrap: break-word;
}
.dcs-taxtable-header {
  font-size: 11pt;
  color: #333333;
  background-color: #eff0f1;
}
.breakrow-inside {
  page-break-inside: avoid;
}
.breakrow-after {
  page-break-after: auto;
}
.dcs-item-row {
  font-size: 11pt;
  border-bottom: 1px solid #ece7e7;
  background-color: #ffffff;
  color: #333333;
}
.dcs-item-sku {
  margin-top: 2px;
  font-size: 10px;
  color: #444444;
}
.dcs-item-desc {
  color: #8e8e8e;
  font-size: 11pt;
}
.dcs-balance {
  background-color: #ffffff;
  font-size: 9pt;
  color: #333333;
}
.dcs-totals {
  font-size: 16pt;
  color: #ffffff;
  background-color: #78ae54;
}
.dcs-notes {
  font-size: 9pt;
}
.dcs-terms {
  font-size: 9pt;
}
.dcs-header-first {
  background-color: #ffffff;
  font-size: 8pt;
  color: #333333;
  height: auto;
}

.dcs-status {
  font-size: 15pt;
  border: 3px solid;
  padding: 3px 8px;
}
.billto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}
.shipto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}

@page :first {
  @top-center {
    content: element(header);
  }
  margin-top: 0.7in;
}

.dcs-template-header {
  padding: 0 0.4in 0 0.55in;
  height: 0.7in;
}

.dcs-template-fill-emptydiv {
  display: table-cell;
  content: ' ';
  width: 100%;
}

.dcs-total {
  font-size: 16pt;
  color: #ffffff;
}
.dcs-payment-details-header {
  color: #333333;
  font-size: 15pt;
}
td .inner-container {
  border-left: 1px solid #ededed;
  padding-left: 20px;
}
.add-info td {
  width: 24%;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
}
.add-info td:last-child {
  width: auto;
}
.inner-container .dcs-label {
  font-weight: bold;
  font-size: 13px;
}
.add-info td:first-child .inner-container {
  border-left: none;
}

// *******************
// Templates Style End
// *******************

.tax-tabs .tab-content {
  padding: 0 !important;
}

.small-text {
  font-size: 11px;
  color: $color-additional !important;
}
.large-text {
  font-size: 18px;
  color: $color-additional !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-auto {
  cursor: auto !important;
}
.cursor {
  cursor: pointer !important;
  &:hover {
    color: #2485e8;
  }
}

.reset-margin {
  margin: 0 !important;
}

.custom-date-picker {
  .date-picker > div,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: '' !important;
  }
}

.custom-box {
  padding: auto;
  width: 100%;
  margin: 0 auto;
  border: 0.1px solid $color-primary;

  svg {
    color: $color-primary;
  }
}

// Breadcrumb style

.breadcrumb {
  color: $color-blue;
  cursor: pointer;

  .active {
    cursor: auto;
  }
}

// Uncontrolled Dropdown
.custom-dropdown {
  .btn {
    margin-right: 0;
    padding: 6px 10px;
    margin-bottom: 10px;
  }

  .dropdown-menu {
    min-width: 7.5rem;
  }

  .custom-menu-date {
    // position: absolute;
    height: 100%;
    width: 500px;
    padding: 0 20px;

    .buttons-toolbar {
      margin-top: 230px;
      margin-left: auto;
    }
  }

  .custom-menu-single-date {
    // position: absolute;
    height: 100%;
    width: 260px;
    padding: 0 20px;

    .buttons-toolbar {
      margin-top: 230px;
      margin-left: auto;
    }
  }
}

.transparent-dropdown {
  .btn {
    margin: 0;
    padding: 6px 10px;
    border: none;

    &:hover,
    &:focus {
      &::before {
        height: 0;
        width: 0;
      }
    }
  }

  .dropdown-menu {
    min-width: 7.5rem;
  }

  .custom-menu-date {
    // position: absolute;
    height: 100%;
    width: 500px;
    padding: 0 20px;

    .buttons-toolbar {
      margin-top: 230px;
      margin-left: auto;
    }
  }
}

.left-bordered-box {
  border: 1px solid #b4e6db;
  border-left: 3px solid #63ceb7;
  padding-top: 15px;
  // padding-bottom: 15px;
  width: 94%;
}

.error-box {
  width: inherit;
  padding: 20px;
  font-size: 15px;
  background-color: lighten($color-additional, 35%);
}

.transaction-add-new {
  margin-left: 7px;
  margin-bottom: -20px;

  small {
    font-weight: bold;
  }
}

.transaction-filter {
  color: $color-blue !important;

  svg {
    fill: $color-blue !important;
    margin-left: 0 !important;
  }
}

.transaction-filter-dropdown {
  @extend .transparent-dropdown;
  .btn {
    padding: 0 5px;
  }
}

.statement-template {
  font-family: Open Sans, 'WebFont-Open Sans';
  max-width: 800px;
  margin: 20px auto;
  padding: 50px 30px;
  box-shadow: 0px 2px 5px #c5c1c1, 0px 0px 5px #c5c1c1;

  .statement-logo {
    width: 50%;
  }
}

.link-btn {
  background: none;
  border: none;
  color: $color-blue;
}

.chart-of-account-detail-head {
  background-color: #fbfafa;
  padding: 20px;
  border-bottom: 2px dotted $color-blue;
}

// Chart of Account hierarchy

.display-child-node {
  position: absolute;
  width: 18px;
  height: 43px;
  margin-left: -27px;
  border-bottom: 1px solid #adadad;
  border-left: 1px solid #adadad;
  transform: translateY(-48px);
}

.display-intermediate-node {
  position: absolute;
  width: 18px;
  height: 43px;
  border-left: 1px solid #adadad;
  transform: translateY(-28px);
}

.folder-icon {
  position: relative;
}

.paste-files-box {
  display: flex;
  justify-content: space-between;
  background-color: lighten($color-gray, 40%);
  margin-left: -10px;
  padding: 20px 25px;
  font-size: 15px;
}

.transparent-btn {
  border: none;
  background: none;
}

.dummy-button {
  all: unset;
}

// Reports CSS

.absolute-text {
  position: absolute;
  color: #777;
  top: 35%;
}

.report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 450px;
}
.report .form-select {
  width: 75% !important;
  border-radius: 0 !important;
  /* padding: 0.375rem 2.25rem 0.375rem 1.75rem; */
  padding: 0.375rem 2.25rem 0.375rem 2.75rem;
  font-size: 14px;
  font-weight: 500;
  color: black;
}
.report .form-select:focus {
  border-color: unset;
  outline: 0;
  box-shadow: none;
}
.report span i {
  position: absolute;
  margin-left: 15px;
  margin-top: -5px;
  color: gray;
  font-size: 13px;
}
.Configure-setting {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Configure-setting a {
  text-decoration: none !important;
  color: #2485e8;
  font-size: 14px;
}
.Configure-setting i {
  color: #2485e8;
  font-size: 14px;
  font-weight: 500;
}

/********* Report Items CSS ***********/

.report-section {
  padding: 10px;
}
.report-item {
  padding: 10px 0;
  border-bottom: 1px dashed #e9e9e9;
}
.report-item a {
  font-size: 14px;
  margin-left: 5px;
}
.report-item i {
  color: gray;
  font-size: 12px;
}

.business-ratio-summary {
  display: flex;
  background-color: #fbfbff;
  border-radius: 10px;
  padding: 10px 20px;

  .business-ratio-items {
    width: 20%;
    padding: 10px;

    &:first-of-type {
      border-right: 1px solid #e3e3ec;
    }

    svg {
      width: 10px;
    }

    div {
      &:nth-child(1) {
        font-size: 15px;
        color: $color-additional;
        margin-bottom: 10px;
      }
    }

    span {
      &:nth-child(1) {
        margin-right: 10px;
        font-size: 20px;
      }

      &:nth-child(2) {
        color: #00d474;
        font-size: 14px;
      }
    }
  }
}

.double-border-bottom {
  border-bottom: 3px double #c6c6c6;
}

.number-bubble {
  background-color: #dfd4d4;
  padding: 5px 10px;
  border-radius: 10rem;
  color: #ffffff;
  margin-right: 5px;
  font-size: 10px;
}

// ================= General Report  - Tax Returns Styling =====================

.reports-container {
  height: calc(100vh - 145px);
  overflow: auto;
  position: relative;
  margin-top: 0;
  padding-top: 0;

  th {
    position: sticky;
    top: -1px;
  }
}

.reports {
  a:hover {
    text-decoration: underline;
  }
}

.report-card {
  min-height: 85vh;
}

.report-table-badge {
  color: #fff;
  padding: 5px 25px;
}

.report-status-unfiled {
  background-color: #fab86e;
}

.report-status-filed {
  background-color: #50a150;
}

.width-fit-content {
  width: fit-content;
}

.tax-returns-table {
  tr:hover {
    background-color: rgb(246, 246, 246);
    cursor: pointer;
  }
}

.tax-return-custom-btn {
  color: #2485e8;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.vat-return-details {
  .banner {
    border: 2px solid #f7f7f7;
    padding: 30px 90px;
  }

  .vat-return-details-table {
    th {
      background-color: #f7f7f7;
    }
  }
}

.vat-audit {
  .custom-download-btn {
    font-size: 16px;
  }
}

.reports-page {
  .report-status-open {
    color: lightskyblue;
  }

  .report-status-closed {
    color: green;
  }

  .report-status-draft {
    color: orange;
  }

  .journal-entry {
    .journal-entry-table {
      .custom-w-15 {
        width: 15%;
      }
    }
  }
}

.base-currency-badge {
  font-size: 11;
  font-weight: bold;
  padding: 1px 3px;
  color: #ffffff;
  background-color: red;
  margin-left: 5px;
}

.collapsable-table-data {
  position: relative;
}

.expand-collapse-btn {
  position: absolute;
  height: 10px;
  background: none;
  border: 1px solid $color-additional;
  left: -15px;
  top: 7px;

  svg {
    margin-top: -18px;
  }
}

.link-clr-add {
  a {
    color: $color-additional;
  }
}

.exchange-rate {
  h6 {
    font-size: 14px;
  }

  h5 {
    font-size: 15px;
    margin: 6px 0;
  }
}

.exchange-rate-edit {
  position: relative;

  svg {
    color: $color-blue;
    margin-top: -5px;
  }

  .exchange-rate-popup {
    position: absolute;
    z-index: 1;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(141, 141, 141, 0.315);
    top: 130%;
    right: 0;
    background-color: #fff;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 65%;
      height: 10px;
      width: 10px;
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
      background-color: #f2f4f7;
      transform: rotate(45deg);
    }

    .popup-head {
      background-color: #f2f4f7;
      border-bottom: 1px solid #ddd;
      padding: 10px;

      svg {
        color: $color-additional;
      }

      button {
        float: right;
        margin: 0;
        padding: 0;
      }
    }

    .popup-body {
      padding: 20px 15px;
      min-width: 200px;
    }
  }
}

.more-details {
  h4:nth-child(2) {
    font-size: 16px;
  }
}

// Reactstrap dropdown

.dropdown-item {
  &:focus {
    background-color: lighten($color-primary, 10%);
  }
  &:active {
    background-color: $color-primary;
  }
}

.dropdown-item.active {
  background-color: $color-primary;
  &:active {
    background-color: $color-primary;
  }
}

// Status colors

.status-draft {
  color: #adabaa;
}

.status-due {
  color: #db5520;
}

.status-overdue {
  color: #db5520;
}

.status-pinv {
  color: #db5520;
}

.status-declined {
  color: #d90505;
}

.status-invoiced {
  color: $color-primary;
}
.status-cancelled {
  color: #dd103b;
}

.status-partially-paid {
  color: $color-primary;
}

.status-closed {
  color: #d90505;
}

.rfp-details-tab {
  .row {
    margin: 15px 0px;
  }
  .custom-text {
    font-size: 14px;
  }
  .custom-heading {
    font-size: 17px;
    font-weight: bold;
    color: #e0c46c;
  }
  .custom-box-wrapper {
    margin: 10px 15px;
    padding: 15px;
    background-color: #dbdcdd;
  }
}

// Styling for File Browser in Operation Module
.rendered-file-browser {
  input {
    outline: none;
  }
  .action-bar {
    display: block !important;
  }
}

.file-manager {
  height: 70vh;
  .chonky-chonkyRoot {
    border: none;
  }
}

//For PDF previewer
.pdf-previewer {
  height: 70vh;
}

.account-info-edit-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  background: none;
  color: $color-blue;
  font-size: 14px;
}

// Quick create button on topbar
.quick-create-dropdown button.icon-btn {
  padding: 1px;
  border-radius: 50%;
}
.w-700 {
  min-width: 700px;
}

.quick-create-menu {
  // min-width: 700px;
  margin-top: 20px;

  .quick-create-menu-header {
    color: grey;
  }

  .dropdown-item {
    background-color: transparent;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    padding: 0px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
  }

  .dropdown-item:active {
    color: #16181b;
  }
}

.dropdown.show .quick-create-icon {
  transform: rotate(225deg);
}
///////

// Topbar Recent Activity
.recent-activity-dropdown .nav-link:hover {
  cursor: pointer;
}

.recent-activity-menu {
  width: 210px;
  margin-top: 18px;
  .dropdown-item {
    background-color: transparent;
    font-size: 14px;
    text-align: left;
    padding: 10px;
    color: #727272;
  }

  .dropdown-item:active {
    color: #ffff;
  }

  .dropdown-item:hover {
    background-color: #e4cb7f;
    color: #fff;

    .icon svg {
      fill: #fff;
    }
  }

  .header {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  .description {
    font-size: 10px;
    text-transform: uppercase;
  }

  .custom-w-15 {
    width: 15% !important;
  }

  .custom-w-85 {
    width: 85% !important;
  }
}

.width-height-fit-content {
  width: fit-content;
  height: fit-content;
  padding: 0px !important;
}

.page-tips-body-wrapper {
  width: 400px;
  padding: 20px;
}

.supplier-email-template {
  border: 1px solid #babbbc;
  padding: 20px 40px;
  width: 40%;

  .logo {
    width: 100%;
    height: 40px;
    object-fit: contain;
  }
  .header-border {
    border-bottom: 5px solid #dfc369;
  }
  .black-box {
    background-color: #0c0c0c;
    padding: 4px 15px;
  }
  .black-box p {
    color: white;
  }
  .black-box-inner {
    border-top: 2px dashed white;
    border-bottom: 2px dashed white;
  }
  .custom-text-primary {
    color: #e0c46c !important;
  }
}

.table-notfound-message {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: inherit;
}

// For Accountant Sidebar Back Button
.custom-sidebar-link {
  .custom-text-primary {
    color: #e0c46c !important;
  }
  &:hover .custom-text-primary {
    color: #fff !important;
  }
}

.requested-events-filter {
  button {
    margin: 0px !important;
  }
}

.custom-mailbox-list {
  max-width: 300px;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.error-template {
  box-shadow: 0px 2px 5px #c5c1c1, 0px 0px 5px #c5c1c1 !important;
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 15px;

  svg {
    height: 20px;
    width: 20px;
  }
}

// Opening Balance form icons
.asset {
  svg {
    color: #fa3e81;
  }
}

.expense {
  svg {
    color: #ffad78;
  }
}

.liability {
  svg {
    color: $color-blue;
  }
}

.equity {
  svg {
    color: #2ddbcf;
  }
}

.income {
  svg {
    color: #fa3e81;
  }
}

.opening-balance-total {
  color: #777;
  background-color: #fdfae4;
  text-align: right;
  font-size: 12px;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.sigImage {
  background-size: 200px 50px;
  width: 340px;
  height: 200px;
  background-color: white;
}

.signature-pad {
  width: 100%;
  height: 200px;
  border: 1px solid #f2f4f7;
}

.dropdown-menu {
  @media screen and (max-width: 500px) {
    transform: translate3d(0px, 24px, 0px) !important;
  }
}

.large-text-link {
  color: #48b2e8;
  padding-left: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.resize-none {
  resize: none;
}

.large-text-td {
  max-width: 500px;
  white-space: break-spaces;
}

// For Custom File Browser
.custom-file-browser {
  .loading-wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
  }

  .rendered-file-browser {
    min-height: 500px;

    input {
      outline: none;
    }

    .action-bar {
      display: block !important;
    }

    .item-actions {
      position: inherit !important;
    }
  }
}

.file-browser-docs-upload-form {
  & .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }

  & input[type='file']::file-selector-button {
    display: none;
  }
}

.file-previewer {
  min-height: 300px;
}
//////////////////////

.custom-icon-btn {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  padding: 4px 9px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 3px;
  font-size: 0.9rem;
  line-height: 1.42857;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }
}

.text-white {
  color: #fff !important;
}

.text-link {
  color: #70bbfd !important;
}

.amount-text {
  font-weight: bolder;
  font-size: 1rem;
  color: rgb(240, 55, 55);
}

.custom-text-primary {
  color: #e0c46c !important;
}

.custom-text-light {
  color: #777 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// Hiding Syncfusion License
#js-licensing {
  display: none !important;
}

// hide scroll bar while allowing functionality
.scroll-hide::-webkit-scrollbar {
  display: none;
}

.custom-primary-dropdown {
  .dropdown-toggle {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0px;
    padding: 4px 9px;
    text-align: center;
    vertical-align: middle;
    display: inherit;
    border-radius: 3px;
    font-size: 0.9rem;
    background-color: $color-primary !important;
    border-color: $color-primary !important;
  }
}

.custom-page-card {
  background-color: #fff;
  min-height: calc(100vh - 140px);
}

.h-fit-content {
  height: fit-content;
}

.page-wrapper {
  .page-header {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
  }
  .page-container {
    padding: 15px;
    min-height: calc(100vh - 145px);
  }
}

.circle-badge {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.fa-spinner {
  line-height: normal;
}

//  ====================== Gap ====================
.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 30px;
}

.gap-4 {
  gap: 40px;
}

.gap-5 {
  gap: 50px;
}


